import styled from "styled-components"
import { device } from "../styles/device"

//* MDX components
const H1 = styled.h1`
  @media ${device.mobileL} {
    font-size: 1.5rem;
  }
  @media ${device.mobileL} {
    font-size: 1.6rem;
  }
`

const H2 = styled.h2`
  font-size: 1.1rem;
  line-height: 2rem;
  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
  @media ${device.tablet} {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  @media ${device.laptop} {
    font-size: 1.4rem;
  }
  @media ${device.laptop} {
    font-size: 1.4rem;
  }
`

const P = styled.p`
  font-size: 1rem;
  @media ${device.mobileL} {
    font-size: 1rem;
  }
  @media ${device.tablet} {
    font-size: 1rem;
  }
  @media ${device.laptop} {
    font-size: 1.1rem;
  }
`

export const responsive = {
  h1: H1,
  h2: H2,
  p: P,
}
