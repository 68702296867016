/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

//* Material-UI
import { ThemeProvider } from "@material-ui/core/styles"
import { theme } from "./styles/theme"

//* FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core"
import { far } from "@fortawesome/pro-regular-svg-icons"
import { fal } from "@fortawesome/pro-light-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"

//* Prevent large icon on load
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

//* Custom components
import Header from "./header"
import Footer from "./footer"

//* MDX components
import { MDXProvider } from "@mdx-js/react"
import FeatureList from "./mdx/features/feature-list"
import Feature from "./mdx/features/feature"
import Email from "./mdx/features/email"
import Phone from "./mdx/features/phone"
import Alert from "../components/mdx/alert"
import GoogleMaps from "../components/mdx/googlemaps"
import GridContainer from "../components/mdx/grid/grid-container"
import GridElement from "../components/mdx/grid/grid-element"
import GridTypography from "../components/mdx/grid/grid-typography"
import EPWrapper from "../components/mdx/expansion-panel/expansion-panel-wrapper"
import EP from "../components/mdx/expansion-panel/expansion-panel-container"
import EPSummary from "../components/mdx/expansion-panel/expansion-panel-summary"
import EPDetails from "../components/mdx/expansion-panel/expansion-panel-details"
import EPTypography from "../components/mdx/expansion-panel/expansion-panel-typography"

import { responsive } from "./mdx/responsive"

//* Styled components
import styled from "styled-components"

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
`

const shortcodes = {
  FeatureList,
  Feature,
  Email,
  Phone,
  GoogleMaps,
  Alert,
  GridContainer,
  GridElement,
  GridTypography,
  EPWrapper,
  EP,
  EPSummary,
  EPDetails,
  EPTypography,
}
const mdxComponents = { ...shortcodes, ...responsive }

library.add(far, fal, fab)
config.autoAddCss = false //* Prevent large icon on load

const Layout = ({ children }) => {
  return (
    <MDXProvider components={mdxComponents}>
      <ThemeProvider theme={theme}>
        <Header />
        <Wrapper>
          <main>{children}</main>
        </Wrapper>
        <Footer />
      </ThemeProvider>
    </MDXProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
