import React, { useContext } from "react"

//* Components
import DesktopFooter from "./footer/desktop"
import MobileFooter from "./footer/mobile"

//* Custom hooks
import { useWindowSize } from "../hooks/hooks"
import { useStaticQuery, graphql } from "gatsby"
import { I18nLinksContext } from "./wrapWithI18nProvider"

const currentTime = new Date()
const year = currentTime.getFullYear()

const Footer = props => {
  const { language } = useContext(I18nLinksContext)

  const data = useStaticQuery(graphql`
    query footerLinks {
      allMdx(filter: { frontmatter: { footerLink: { ne: null } } }) {
        edges {
          node {
            frontmatter {
              title
              slug
              footerLink
            }
            fields {
              language
            }
          }
        }
      }
    }
  `)

  const { allMdx } = data
  const { edges } = allMdx

  const pages = edges.filter(elem => {
    return elem.node.fields.language === language
  })
  const pagesOrdered = pages.sort((a, b) => {
    return a.node.frontmatter.footerLink - b.node.frontmatter.footerLink
  })

  const size = useWindowSize()
  return size.width > 768 ? (
    <DesktopFooter
      size={size}
      time={currentTime}
      year={year}
      pages={pagesOrdered}
      language={language}
    />
  ) : (
    <MobileFooter
      size={size}
      time={currentTime}
      year={year}
      pages={pagesOrdered}
      language={language}
    />
  )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
