import React from "react"

import styled from "styled-components"
import { device } from "../styles/device"

//* Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AlertWrapper = styled.div`
  padding: 2.5% 4%;
  text-align: left;
  margin-top: 2rem;
  background-color: rgba(205, 45, 38, 0.3);
  border-color: rgba(205, 45, 38, 1);
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
`

const AlertTitle = styled.h2`
  font-weight: 700;
  line-height: 1.5;
  color: rgba(45, 53, 63, 1);
  margin: 0.5rem 0 1rem 0;
  font-size: 1rem;
  @media ${device.mobileM} {
    font-size: 1.1rem;
  }
  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
`

const AlertText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(45, 53, 63, 1);
  margin: 0;
`

const Alert = props => {
  const { icon, size, color, title, text } = props

  return (
    <AlertWrapper>
      <FontAwesomeIcon
        icon={icon !== undefined ? icon : ["far", "home"]}
        size={size !== undefined ? size : "lg"}
        color={color !== undefined ? color : "rgba(155,155,155,1)"}
      />
      <AlertTitle>{title}</AlertTitle>
      <AlertText>{text}</AlertText>
    </AlertWrapper>
  )
}

export default Alert
