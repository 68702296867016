//* Responsive design using styled components
//* see: https://jsramblings.com/how-to-use-media-queries-with-styled-components/

const breakpoints = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 414,
  mobileLL: 667, //* Mobile landscape
  tablet: 768,
  mobileXL: 812,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
}

const size = {
  mobileS: `${breakpoints.mobileS}px`,
  mobileM: `${breakpoints.mobileM}px`,
  mobileL: `${breakpoints.mobileL}px`,
  mobileLL: `${breakpoints.mobileLL}px`,
  tablet: `${breakpoints.tablet}px`,
  mobileXL: `${breakpoints.mobileXL}px`,
  laptop: `${breakpoints.laptop}px`,
  laptopL: `${breakpoints.laptopL}px`,
  desktop: `${breakpoints.desktop}px`,
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileLL: `(min-width: ${size.mobileLL})`,
  tablet: `(min-width: ${size.tablet})`,
  mobileXL: `(min-width: ${size.mobileXL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}
