import React from "react"

//* Gatsby
import { Link } from "gatsby"

const styles = {
  wrapper: {
    borderTop: "1px solid #C6C6C6",
    marginTop: "40px",
    paddingTop: "40px",
    marginBottom: "40px",
    width: "100%",
    textAlign: "center",
  },
  inner: {
    wrapper: { marginTop: "30px" },
    paragraph: {
      color: "#C8CED3",
      fontSize: "70%",
      letterSpacing: "1px",
      fontWeight: 300,
      textAlign: "center",
      marginBottom: 0,
      marginTop: "5px",
    },
  },
  link: {
    inactive: {
      display: "block",
      fontFamily: "montserrat, open-sans, sans-serif",
      boxShadow: "none",
      textDecoration: "none",
      color: "#848484",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "60%",
      margin: "0 1.2rem",
    },
    active: {
      display: "block",
      fontFamily: "montserrat, open-sans, sans-serif",
      boxShadow: "none",
      textDecoration: "none",
      color: "#256EAA",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontSize: "60%",
      margin: "0 1.2rem",
    },
  },
}

const MobileFooter = ({ year, pages, language }) => {
  return (
    <footer>
      <div style={styles.wrapper}>
        {pages.map((page, key) => {
          return (
            <Link
              key={"legal-" + key}
              to={
                language.code !== language.sitebase
                  ? `/${language.code}/${page.node.frontmatter.slug}`
                  : `/${page.node.frontmatter.slug}`
              }
              style={styles.link.inactive}
              activeStyle={styles.link.active}
            >
              {page.node.frontmatter.title}
            </Link>
          )
        })}
        <div style={styles.inner.wrapper}>
          <p style={styles.inner.paragraph}>
            Copyright &copy; 2006 {year > 2006 ? "-" : null}{" "}
            {year > 2010 ? year : null} &middot; Monika Hauser &middot;
            Psychologische Beratung &middot; Standortbestimmung &middot;
            Therapie
          </p>
        </div>
      </div>
    </footer>
  )
}

MobileFooter.propTypes = {}

MobileFooter.defaultProps = {}

export default MobileFooter
