import React from "react"

//* Material UI
import Typography from "@material-ui/core/Typography"

const GridTypography = props => {
  const { variant, component, style, children, ...rest } = props

  if (style !== undefined) {
    return (
      <Typography
        variant={variant !== undefined ? variant : "body2"}
        component={component !== undefined ? component : "p"}
        style={{ ...style }}
        {...rest}
      >
        {children}
      </Typography>
    )
  }
  return (
    <Typography
      variant={variant !== undefined ? variant : "body2"}
      component={component !== undefined ? component : "p"}
      {...rest}
    >
      {children}
    </Typography>
  )
}

export default GridTypography
