import React from "react"
import { makeStyles } from "@material-ui/core/styles"

//* Devices for responsive design
import { device } from "../../styles/device"

//* Styled components
import styled from "styled-components"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
}))

const ExpansionPanelWrapper = styled.div`
  margin: 3rem 0 3rem 0;
  @media ${device.tablet} {
    margin: 4rem 0 4rem 0;
  }
  @media ${device.laptopL} {
    margin: 5rem 0 5rem 0;
  }
`

const ExpansionPanelTitle = styled.h2`
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: rgba(45, 53, 63, 1);
  margin: 0 0 2rem 0;
  @media ${device.mobileM} {
    font-size: 1.1rem;
  }
  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
`

const EPWrapper = ({ title, children, wrapperProps, titleProps }) => {
  const classes = useStyles()

  return (
    <ExpansionPanelWrapper {...wrapperProps}>
      <ExpansionPanelTitle {...titleProps}>{title}</ExpansionPanelTitle>
      <div className={classes.root}>{children}</div>
    </ExpansionPanelWrapper>
  )
}

export default EPWrapper
