import React from "react"

const GoogleMaps = ({ ...props }) => {

    return(
        <iframe
            uc-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.6401687716098!2d8.725156616233761!3d47.49692280354039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a999ded661ee9%3A0x8fad3d666fa24550!2sTurmhaldenstrasse%209%2C%208400%20Winterthur!5e0!3m2!1sen!2sch!4v1643274982629!5m2!1sen!2sch"
            title={props.title}
            style={
                {
                    width: "100%",
                    height: "100%",
                    border: "none"
                }
            }
            allowFullScreen=""
            loading="lazy">
        </iframe>
    )

}

export default GoogleMaps

/*<iframe
                src="about:blank"
                title={"Monika Hauser Psychologische Beratung Standortbestimmung Therapie"}
                data-cmp-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.6401687716098!2d8.725156616233761!3d47.49692280354039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a999ded661ee9%3A0x8fad3d666fa24550!2sTurmhaldenstrasse%209%2C%208400%20Winterthur!5e0!3m2!1sde!2sch!4v1643105390461!5m2!1sde!2sch"
                style={
                    {
                        border: "0",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: "100%",
                        height: "100%",
                    }
                }
                allowFullScreen=""
                loading="lazy">
            </iframe>*/
