import React from "react"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const EPSummary = ({ icon, size, color, id, children, ...rest }) => {
  return (
    <AccordionSummary
      expandIcon={
        <FontAwesomeIcon
          icon={icon !== undefined ? icon : faChevronDown}
          size={size !== undefined ? size : "xs"}
          color={color !== undefined ? color : "rgba(155,155,155,1)"}
        />
      }
      aria-controls={`${id}-content`}
      id={`${id}-header`}
      {...rest}
    >
      {children}
    </AccordionSummary>
  )
}

export default EPSummary
