import { useEffect, useState } from "react"

const getSize = isClient => {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }
}

// Hook for window resize
export const useWindowSize = () => {
  const isClient = typeof window === "object"
  const [windowSize, setWindowSize] = useState(getSize(isClient))

  useEffect(() => {
    if (typeof window === undefined) return

    const handleResize = () => setWindowSize(getSize(isClient))
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isClient])

  /*
  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize(isClient))
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)


  }, [isClient]) // Empty array ensures that effect is only run on mount and unmount

   */

  return windowSize
}
