import React from "react"

//* Material UI
import Grid from "@material-ui/core/Grid"

const GridElement = props => {
  const { xs, sm, md, lg, children, ...rest } = props

  return (
    <Grid
      item
      xs={xs !== undefined ? +xs : 12}
      sm={sm !== undefined ? +sm : 6}
      md={md !== undefined ? +md : 4}
      lg={lg !== undefined ? +lg : 4}
      {...rest}
    >
      {children}
    </Grid>
  )
}

export default GridElement
