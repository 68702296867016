import React from "react"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
  },
}))

const EPDetails = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <AccordionDetails className={classes.root} {...rest}>
      {children}
    </AccordionDetails>
  )
}

export default EPDetails
