import React, { useContext } from "react"

//* Components
import DesktopHeader from "./header/desktop"
import MobileHeader from "./header/mobile"

//* Custom hooks
import { useWindowSize } from "../hooks/hooks"
import { graphql, useStaticQuery } from "gatsby"
import { I18nLinksContext } from "./wrapWithI18nProvider"

const Header = props => {
  const { language } = useContext(I18nLinksContext)

  const data = useStaticQuery(graphql`
    query headerLinks {
      allMdx(filter: { frontmatter: { menuItem: { ne: null } } }) {
        edges {
          node {
            frontmatter {
              name
              slug
              menuItem
            }
            fields {
              language
            }
          }
        }
      }
    }
  `)

  const size = useWindowSize()

  const { allMdx } = data
  const { edges } = allMdx

  const pages = edges
    .filter(elem => {
      return elem.node.fields.language === language
    })
    .sort((a, b) => {
      return a.node.frontmatter.menuItem - b.node.frontmatter.menuItem
    })

  return (
    <React.Fragment>
      <DesktopHeader pages={pages} size={size} />
      <MobileHeader pages={pages} size={size} />
    </React.Fragment>
  )
}

Header.propTypes = {}

Header.defaultProps = {}

export default Header
