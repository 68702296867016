import React, { useContext } from "react"

//* Gatsby
import { Link, navigate } from "gatsby"

//* Material-UI
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Popover from "@material-ui/core/Popover"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"

//* Styled components
import styled from "styled-components"
import { device } from "../styles/device"

//* Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//* i18Next
import { I18nLinksContext } from "../wrapWithI18nProvider"
import { useTranslation } from "react-i18next"

//* Flagicons
//* import FlagIcon from "../flagicons"

//* Define Material-UI styles
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1, 1, 1, 1),
    padding: theme.spacing(1, 2, 1, 2),
    color: "rgba(45,53,63,0.7)",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      color: "rgba(45,53,63,1)",
    },
  },
  homeIcon: {
    marginRight: 0,
  },
}))

const styles = {
  link: {
    inactive: {
      fontFamily: "montserrat, open-sans, sans-serif",
      boxShadow: "none",
      textDecoration: "none",
      color: "inherit",
      letterSpacing: "0px",
      textTransform: "none",
      fontSize: "100%",
      margin: "0 0.2rem",
    },
    active: {
      fontFamily: "montserrat, open-sans, sans-serif",
      boxShadow: "none",
      textDecoration: "none",
      color: "rgba(45,53,63,1)",
      letterSpacing: "0px",
      textTransform: "none",
      fontSize: "100%",
      margin: "0 0.2rem",
    },
  },
}

const Wrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
  }
`

const LngWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
  }
`

const DesktopHeader = ({ pages }) => {
  const { language, alternatelinks } = useContext(I18nLinksContext)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { i18n } = useTranslation("common")
  const classes = useStyles()

  const handleHomeClick = event => {
    event.preventDefault()
    navigate(i18n.language !== language ? "/" + i18n.language : "/")
  }

  const handleLngOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleLngClose = () => {
    setAnchorEl(null)
  }

  const handleLngSelect = lngCode => {
    return event => {
      event.preventDefault()
      navigate(
        alternatelinks.find(elem => {
          return elem.language.code === lngCode
        }).path
      )
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? "lng-popover" : undefined

  const hasLanguages = alternatelinks.length > 1

  return (
    <Wrapper>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <div style={{ flex: 1 }} />
          <Button
            classes={{
              root: classes.button, // class name, e.g. `classes-nesting-root-x`
              startIcon: classes.homeIcon, // class name, e.g. `classes-nesting-label-x`
            }}
            onClick={handleHomeClick}
            disabled={false}
            color="secondary"
            aria-label="home"
            disableElevation={true}
            disableRipple={true}
            children={
              <Link
                to={
                  i18n.language !== language
                    ? "/" + i18n.language
                    : "/"
                }
                style={styles.link.inactive}
                activeStyle={styles.link.active}
              >
                {"Home"}
              </Link>
            }
          />

          {pages.map((page, key) => {
            let inputProps = {
              className: classes.button,
              key: "menu-" + key,
              size: "medium",
            }
            if (page.node.frontmatter.icon !== null && undefined)
              inputProps.startIcon = (
                <FontAwesomeIcon
                  icon={page.node.frontmatter.icon}
                  size={"lg"}
                  color="inherit"
                />
              )

            return (
              <Button
                {...inputProps}
                children={
                  <Link
                    to={
                      i18n.language !== language
                        ? `/${language}/${page.node.frontmatter.slug}`
                        : `/${page.node.frontmatter.slug}`
                    }
                    style={styles.link.inactive}
                    activeStyle={styles.link.active}
                  >
                    {page.node.frontmatter.name}
                  </Link>
                }
                disableRipple={true}
              ></Button>
            )
          })}
          <div style={{ flex: 1 }} />
          {hasLanguages === true ? (
            <React.Fragment>
              <Button
                variant="text"
                color="secondary"
                className={classes.button}
                onClick={handleLngOpen}
                startIcon={
                  <FontAwesomeIcon
                    icon={["far", "language"]}
                    size={"sm"}
                    color="inherit"
                  />
                }
                endIcon={
                  <FontAwesomeIcon
                    icon={["far", "chevron-down"]}
                    size={"xs"}
                    color="inherit"
                  />
                }
              >
                {
                  <LngWrapper>
                    {
                      alternatelinks.find(
                        link => link.language.code === i18n.language
                      ).language.name
                    }
                  </LngWrapper>
                }
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleLngClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List component="nav" aria-label="language selector">
                  {alternatelinks &&
                    alternatelinks
                      .filter(link => link.language.code !== i18n.language)
                      .map((link, i) => {
                        return (
                          <ListItem
                            key={i}
                            button
                            value={link.language.code}
                            onClick={handleLngSelect(link.language.code)}
                          >
                            {/*<ListItemIcon>
                              <FlagIcon code={link.language.flagcode} />
                            </ListItemIcon>*/}
                            <ListItemText primary={link.language.name} />
                          </ListItem>
                        )
                      })}
                </List>
              </Popover>
            </React.Fragment>
          ) : null}
        </Toolbar>
      </AppBar>
    </Wrapper>
  )
}

DesktopHeader.propTypes = {}

DesktopHeader.defaultProps = {}

export default DesktopHeader
