import React, { useRef, useEffect, useState } from "react"

//* Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/pro-regular-svg-icons"

//* Styled components
import styled from "styled-components"

//* Material UI
import Grid from "@material-ui/core/Grid"

import { gsap } from "gsap"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

//* Custom hooks
import { useWindowSize } from "../../../hooks/hooks"

//* GSAP
const fadeIn = delay => {
  return targets => {
    gsap.to(targets, {
      delay: delay,
      opacity: 1,
      duration: 0.1,
      scaleX: 1,
      scaleY: 1,
    })
  }
}

const fadeOut = targets => {
  gsap.to(targets, {
    opacity: 0,
    duration: 0.1,
    scaleX: 0,
    scaleY: 0,
  })
}

//* Styled components
const FeatureWrapper = styled.div`
  padding: 2.5% 4%;
  margin: 0;
  text-align: center;
  background-color: transparent;
  border: none;
`

const FeatureTitle = styled.p`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: rgba(45, 53, 63, 1);
  margin: 1rem 0 0.5rem 0;
`

const FeatureText = styled.p`
  font-size: 0.8rem;
  line-height: 1.5;
  color: rgba(45, 53, 63, 1);
  margin: 0;
`

const Feature = props => {
  const {
    xs,
    sm,
    md,
    lg,
    size,
    icon,
    color,
    title,
    text,
    delay,
    ...rest
  } = props
  const d = delay !== undefined ? delay : 0
  const fadeInDelay = fadeIn(d)
  const windowsize = useWindowSize()

  const [elementHeight, setElementHeight] = useState(null)
  const elementRef = useRef(null)
  const isInitiallyVisible =
    typeof window === "undefined" || windowsize.height > 300

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (
        windowsize.height - elementHeight <= currPos.y &&
        windowsize.height - elementHeight >= prevPos.y
      ) {
        fadeOut([elementRef.current])
      } else if (
        windowsize.height - elementHeight >= currPos.y &&
        windowsize.height - elementHeight <= prevPos.y
      ) {
        fadeInDelay([elementRef.current])
      }
    },
    [elementHeight, windowsize],
    elementRef
  )

  useEffect(() => {
    setElementHeight(5)
  }, [])

  return (
    <Grid
      item
      xs={xs !== undefined ? +xs : 12}
      sm={sm !== undefined ? +sm : 6}
      md={md !== undefined ? +md : 4}
      lg={lg !== undefined ? +lg : 4}
      {...rest}
    >
      <FeatureWrapper
        ref={elementRef}
        style={{
          opacity: isInitiallyVisible === true ? 1 : 0,
        }}
      >
        <FontAwesomeIcon
          icon={icon !== undefined ? icon : faHome}
          size={size !== undefined ? size : "3x"}
          color={color !== undefined ? color : "rgba(155,155,155,1)"}
        />
        <FeatureTitle>{title}</FeatureTitle>
        <FeatureText>{text}</FeatureText>
      </FeatureWrapper>
    </Grid>
  )
}

export default Feature
