import React from "react"

//* Devices for responsive design
import { device } from "../../styles/device"

//* Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

//* Styled components
import styled from "styled-components"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}))

const GridWrapper = styled.div`
  margin: 3rem 0 3rem 0;
  @media ${device.tablet} {
    margin: 4rem 0 4rem 0;
  }
  @media ${device.laptopL} {
    margin: 5rem 0 5rem 0;
  }
`

const GridTitle = styled.h2`
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: rgba(45, 53, 63, 1);
  margin: 0 0 2rem 0;
  @media ${device.mobileM} {
    font-size: 1.1rem;
  }
  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
`

const GridContainer = ({
  title,
  children,
  wrapperProps,
  titleProps,
  containerProps,
}) => {
  const classes = useStyles()

  return (
    <GridWrapper {...wrapperProps}>
      <GridTitle {...titleProps}>{title}</GridTitle>
      <div className={classes.root}>
        <Grid container spacing={10} {...containerProps}>
          {children}
        </Grid>
      </div>
    </GridWrapper>
  )
}

export default GridContainer
