import { createTheme } from "@material-ui/core/styles"
//* import purple from '@material-ui/core/colors/purple';
//* import green from '@material-ui/core/colors/green';
import orange from "@material-ui/core/colors/orange"

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
      contrastText: "rgba(45,53,63,1)",
    },
    secondary: {
      main: "rgba(45,53,63,1)",
      contrastText: "#ffffff",
    },
  },
  status: {
    danger: orange[500],
  },
  typography: {
    subtitle2: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "1rem",
    },
  },
})
