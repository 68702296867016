import styled from "styled-components"
import { device } from "./device"

//* Wrapper
//* --------------------------------------------------------------------------------------------------------------------
//* Wraps the page ex (1) header, (2) featured images, (3) footer
export const Wrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  font-size: 16px;
  max-width: 1200px;
  @media ${device.mobileS} {
    margin: 0 auto;
    width: 90%;
    font-size: 16px;
    max-width: 1200px;
  }
  @media ${device.laptop} {
    margin: 0 auto;
    width: 90%;
    font-size: 16px;
    max-width: 1200px;
  }
`

//* Featured images
//* --------------------------------------------------------------------------------------------------------------------
//* Image wrapper (title image)
export const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.45rem;
  position: relative;
`

//* Image overlay (for title and description)
export const ImageOverlay = styled.div`
  display: none;
  margin: 0;
  text-align: center;
  left: 50%;
  width: 90%;
  @media ${device.tablet} {
    display: block;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, -20%);
    padding: 1.25% 2%;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
  }
  @media ${device.laptopL} {
    transform: translate(-50%, -30%);
    max-width: 1200px;
    width: 90%;
    padding: 1.5% 3%;
  }
`

//* Image title (title and description)
export const ImageTitle = styled.h1`
  display: none;
  margin: 0;
  color: rgba(45, 53, 63, 1);
  line-height: 1.5;
  font-weight: 700;
  @media ${device.tablet} {
    display: block;
    font-size: 1.1rem;
  }
  @media ${device.laptop} {
    font-size: 1.3rem;
  }
  @media ${device.laptopL} {
    font-size: 1.5rem;
  }
`

//* Image title tagline above
export const ImageTitleTaglineAbove = styled.h2`
  display: none;
  margin: 0 auto 0.5rem auto;
  color: rgba(45, 53, 63, 1);
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  @media ${device.laptopL} {
    display: block;
    font-size: 1.2rem;
  }
`

//* Image title tagline below
export const ImageTitleTaglineBelow = styled.h2`
  display: none;
  margin: 0.5rem auto 0 auto;
  color: rgba(45, 53, 63, 1);
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  @media ${device.mobileL} {
    display: block;
  }
  @media ${device.laptop} {
    font-size: 1.1rem;
  }
  @media ${device.laptopL} {
    font-size: 1.2rem;
  }
`

//* Special wrapper for taglines on tablets
export const BelowImageWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  font-size: 16px;
  max-width: 1200px;
  text-align: center;
  @media ${device.mobileS} {
  }
  @media ${device.laptop} {
  }
`
//* Title (below image for mobile devices)
export const BelowImageTitle = styled.h1`
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  line-height: 1.5;
  font-weight: 700;
  color: ${props =>
    props.light ? "rgba(255, 255, 255, 0.8)" : "rgba(45, 53, 63, 1)"};
  color: rgba(45, 53, 63, 1);
  font-size: 1rem;
  @media ${device.mobileM} {
    font-size: 1.1rem;
  }
  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
  @media ${device.tablet} {
    display: none;
  }
`

//* Title tagline above (below image for mobile devices)
export const BelowImageTitleTaglineAbove = styled.h2`
  display: none;
  margin: 0 auto 0.5rem auto;
  font-size: 1rem;
  line-height: 1.5;
  @media ${device.tablet} {
    display: block;
    margin-bottom: 2rem;
  }
  @media ${device.laptopL} {
    display: none;
  }
`

//* Title tagline below (below image for mobile devices)
export const BelowImageTitleTaglineBelow = styled.h2`
  display: block;
  line-height: 1.5;
  margin: 0.5rem auto 2rem auto;
  font-size: 0.9rem;
  @media ${device.mobileM} {
    font-size: 1rem;
  }
  @media ${device.mobileL} {
    font-size: 1.1rem;
  }
  @media ${device.tablet} {
    display: none;
  }
`

//* Special case: No featured image (e.g. legal disclosure)
//* --------------------------------------------------------------------------------------------------------------------
//* Title wrapper in case we have no image
export const PlainTitleWrapper = styled.div`
  padding: 5% 4%;
  text-align: center;
  margin: 0;
  background-color: rgba(45, 53, 63, 0.02);
  border-top: 1px solid rgba(198, 198, 198, 1);
  border-bottom: 1px solid rgba(198, 198, 198, 1);
`

export const PlainTitle = styled.h1`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  line-height: 1.5;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 1.1rem;
  }
  @media ${device.laptop} {
    font-size: 1.3rem;
  }
  @media ${device.laptopL} {
    font-size: 1.5rem;
  }
`

//* Fragment dividers
export const FragmentDivider = styled.div`
  display: block;
  font-size: 1rem;
  margin: 2rem 0 0 0;
  padding-top: 2rem;
  border-top: ${props =>
    props.border ? "1px solid rgba(198, 198, 198, 1)" : "none"};
  @media ${device.mobileL} {
    margin: 0.5rem 0 0 0;
  }
  @media ${device.tablet} {
    margin: 1rem 0 0 0;
  }
`
