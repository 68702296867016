/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  location,
  url,
  description,
  lang,
  meta,
  title,
  image,
  robots,
  keywords,
  datePublished,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            organisation
            siteUrl
          }
        }
      }
    `
  )

  const today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  const metaDatePublished = datePublished || dd + "/" + mm + "/" + yyyy


  const baseSchema = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: `${site.siteMetadata.siteUrl}.${location.pathname}`,
    name: title,
    "datePublished": metaDatePublished,
    alternateName: site.siteMetadata.title,
  }

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title
  const metaImage = image || site.siteMetadata.image
  const metaRobots = robots || site.siteMetadata.robots
  const metaKeywords = keywords || site.siteMetadata.keywords

  const baseMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: `robots`,
      content: metaRobots,
    },
    {
      name: `keywords`,
      content: metaKeywords,
    },
    /*
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:image`,
      content: metaImage,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    */
  ]

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={site.siteMetadata.title}
        titleTemplate={`%s | ${metaTitle}`}
        meta={Array.isArray(meta) ? baseMeta.concat(meta) : baseMeta}
      >
        <link
          rel="canonical"
          href={`${site.siteMetadata.siteUrl}${location.pathname}`}
        />
        <link rel="preconnect" href="//app.usercentrics.eu"/>
        <link rel="preconnect" href="//api.usercentrics.eu"/>
        <link rel="preconnect" href="//privacy-proxy.usercentrics.eu"/>
        <link rel="preload" href="//app.usercentrics.eu/browser-ui/latest/loader.js" as="script"/>
        <link rel="preload" href="//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js" as="script"/>
        <script id="usercentrics-cmp" data-settings-id="t6Fxw54fS" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-tcf-enabled></script>
        <script type="application/javascript" src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></script>

        <script type="application/ld+json">{JSON.stringify(baseSchema)}</script>
      </Helmet>
    </React.Fragment>
  )
}

Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Seo
