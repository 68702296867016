import React from "react"

//* Material UI
import Typography from "@material-ui/core/Typography"

const EPTypography = props => {
  const { variant, paragraph, component, children, ...rest } = props

  return (
    <Typography
      variant={variant !== undefined ? variant : "body2"}
      paragraph={paragraph !== undefined ? paragraph : true}
      component={component !== undefined ? component : "p"}
      {...rest}
    >
      {children}
    </Typography>
  )
}

export default EPTypography
