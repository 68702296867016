import React, { useContext } from "react"

//* Gatsby
import { navigate } from "gatsby"

//* Material-UI
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

import { makeStyles } from "@material-ui/core/styles"

//* Styled components
import styled from "styled-components"
import { device } from "../styles/device"

//* Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/pro-regular-svg-icons"

//* i18Next
import { I18nLinksContext } from "../wrapWithI18nProvider"
import { useTranslation } from "react-i18next"

//* Flagicons
//* import FlagIcon from "../flagicons"
import Popover from "@material-ui/core/Popover"

//* Define Material-UI styles
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1, 1, 1, 1),
    padding: theme.spacing(1, 2, 1, 2),
    color: "rgba(45,53,63,0.7)",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      color: "rgba(45,53,63,1)",
    },
  },
  startIcon: {
    marginRight: 0,
  },
  list: {
    width: 250,
  },
  langlist: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}))

const Wrapper = styled.div`
  display: block;
  @media ${device.laptop} {
    display: none;
  }
`

const MobileHeader = ({ pages, lang }) => {
  const { language, alternatelinks } = useContext(I18nLinksContext)
  const [anchorElMenu, setAnchorElMenu] = React.useState(null)
  const [anchorElLang, setAnchorElLang] = React.useState(null)

  const { i18n } = useTranslation("common")
  const classes = useStyles()

  const handleHomeClick = event => {
    event.preventDefault()
    setAnchorElMenu(null)
    setAnchorElLang(null)
    navigate(i18n.language !== language ? "/" + i18n.language : "/")
  }

  const handleMenuSelect = link => {
    return event => {
      event.preventDefault()
      setAnchorElMenu(null)
      setAnchorElLang(null)
      navigate(
        i18n.language !== language
          ? "/" + i18n.language + "/" + link
          : "/" + link
      ) //* This needs to be adjusted
    }
  }

  const handleMenuOpen = event => {
    setAnchorElMenu(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorElMenu(null)
  }

  const handleLngOpen = event => {
    setAnchorElLang(event.currentTarget)
  }

  const handleLngClose = () => {
    setAnchorElLang(null)
  }

  const handleLngSelect = lngCode => {
    return event => {
      event.preventDefault()
      navigate(
        alternatelinks.find(elem => {
          return elem.language.code === lngCode
        }).path
      )
    }
  }

  const menuOpen = Boolean(anchorElMenu)
  const idMenu = menuOpen ? "menu-popover" : undefined

  const langOpen = Boolean(anchorElLang)
  const idLang = langOpen ? "lng-popover" : undefined

  return (
    <Wrapper>
      <AppBar position="static">
        <Toolbar>
          <div style={{ flex: 1 }} />
          <Button
            classes={{
              root: classes.button, // class name, e.g. `classes-nesting-root-x`
              startIcon: classes.startIcon, // class name, e.g. `classes-nesting-label-x`
            }}
            onClick={handleMenuOpen}
            disabled={false}
            color="secondary"
            aria-label="menu"
            startIcon={
              <FontAwesomeIcon icon={faBars} size={"sm"} color="inherit" />
            }
            disableElevation={true}
            children={""}
            disableRipple={true}
          />
          <div style={{ flex: 1 }} />

          <Popover
            id={idMenu}
            open={menuOpen}
            anchorEl={anchorElMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <List component="nav" aria-label="language selector">
              <ListItem button value={"home"} onClick={handleHomeClick}>
                <ListItemText primary={"Home"} />
              </ListItem>
              {alternatelinks.length > 1 ? (
                <ListItem button value={"home"} onClick={handleLngOpen}>
                  <ListItemText primary={"Home"} />
                </ListItem>
              ) : null}
              {pages.map((page, i) => {
                return (
                  <ListItem
                    key={i}
                    button
                    value={page.menuItem}
                    onClick={handleMenuSelect(page.node.frontmatter.slug)}
                  >
                    <ListItemText primary={page.node.frontmatter.name} />
                  </ListItem>
                )
              })}
            </List>
          </Popover>

          <Popover
            id={idLang}
            open={langOpen}
            anchorEl={anchorElLang}
            onClose={handleLngClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <List component="nav" aria-label="language selector">
              {alternatelinks &&
                alternatelinks
                  .filter(link => link.language.code !== i18n.language)
                  .map((link, i) => {
                    return (
                      <ListItem
                        key={"lang" + i}
                        button
                        value={link.language.code}
                        onClick={handleLngSelect(link.language.code)}
                      >
                        {/*<ListItemIcon>
                          <FlagIcon code={link.language.flagcode} />
                        </ListItemIcon>*/}
                        <ListItemText primary={link.language.name} />
                      </ListItem>
                    )
                  })}
            </List>
          </Popover>
        </Toolbar>
      </AppBar>
    </Wrapper>
  )
}

MobileHeader.propTypes = {}

MobileHeader.defaultProps = {}

export default MobileHeader
